<template>
<v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col>
        <div class="register"  v-if="this.view == 'login'">
          <h1 class="font-weight-light mt-4 mb-2">Login</h1>
          <p v-if="resetSend == true">Controleer je e-mail om je wachtwoord in te stellen.</p>
          <v-form @submit.prevent="submit">
            <v-text-field
                  v-model="formdata.email"
                  label="E-mail adres"
                  :type="'email'"
                  required
                  outlined
                  autocomplete="username"
                ></v-text-field>
            <!-- <input type="email" placeholder="Email" v-model="formdata.email" /> -->
            <v-text-field
                  v-model="formdata.password"
                  label="Wachtwoord"
                  required
                  outlined
                  autocomplete="current-password"
                  :type="'password'"
                ></v-text-field>
            
            <v-btn @click="submit()" class="mt-5" color="primary">Login bij Rondje Maarn</v-btn> &nbsp;
            <v-btn @click="toggleView()" class="mt-5" color="secondary" text>Dom! wachtwoord vergeten</v-btn>
            {{error}}
          </v-form>
        </div>
        <div class="register" v-else>
          <h1 class="font-weight-light mt-3 mb-3">Reset je Wachtwoord</h1>
          
          <v-form @submit.prevent="submit">
            <v-text-field
                  v-model="formdata.email"
                  label="email adres"
                  :type="'email'"
                  required
                  outlined
                 
                ></v-text-field>
         
            <v-btn @click="submitReset()" color="primary">Reset je wachtwoord</v-btn> &nbsp; 
            <v-btn @click="toggleView()" color="secondary" outlined>Terug</v-btn>
            {{error}}
          </v-form>
        </div>
      </v-col>
    </v-row></v-container>
</template>
<script>
import firebase from "firebase/app";
import store from "../store";
import {usersRef} from "../store/firestore"


export default {
  name: "Login",
  data() {
    return {
      formdata: {
        email: "",
        password: ""
      },
      view:"login",
      resetSend: false,
      error: null
    };
  },
  methods: {
    toggleView(){
      if(this.view == "reset"){
        this.view = "login";
      }else{
        this.view = "reset";
        this.resetSend = false;
      }
    },
    submitReset(){
      var that = this;
      firebase.auth().sendPasswordResetEmail(that.formdata.email).then(function() {
        // Email sent.
        that.resetSend = true;
        that.toggleView();
      }).catch(function(error) {
        // An error happened.
        that.error = error;
      });
    },
    submit() {
      var that = this;
      firebase
        .auth()
        .signInWithEmailAndPassword(that.formdata.email, that.formdata.password)
        .then(user => {
          //check of in users, als niet --> toevoegen usersRef
          usersRef.where("uid", "==", user.user.uid).get()
          .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    var test= doc.data();
                    test.id = doc.id
                    
                    store.dispatch("fetchUser", test);
                    that.$router.push( '/')
                });
                if(querySnapshot.doc.length == 0){
                  that.error = "Je gegevens werden niet gevonden... Sorry."
                }
            })
            
            .catch(function(){
              //console.log('oeps fout');
            })
          
        })
        .catch(err => {
          this.error = err.message;
        });
    }
  }
};
</script>